.legendWrapper {
  text-align: left;
  display: inline-block;
  margin-left: 50px;
}

.chartLegend {
  vertical-align: middle;
  line-height: 1.5;
  span {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0 .5em;
    vertical-align: middle;
  }

  p {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}